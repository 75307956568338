// @ts-nocheck
import React, { useState, useEffect } from "react";
import "../styles/App.css";

const Project = ({ id, name, link, skills, img, className = "" }) => {
    return (
        <div id={id} className={`project ${className}`}>
            <div
                onClick={() => {}}
                className="projImage"
                style={{
                    backgroundImage: `url("/images/${img}.png")`,
                    cursor: link ? "cursor" : "auto",
                }}
            ></div>
            <div className="caption">
                {!link ? (
                    <h2 style={{ marginRight: "16px" }}>{name}</h2>
                ) : (
                    <a href={link} target="_blank" rel="noopener noreferrer">
                        <h2 style={{ marginRight: "16px", textDecoration: "underline" }}>{name}</h2>
                    </a>
                )}

                <div className="skills italic">{skills ? skills.map((item, i) => <p key={i}>{item}</p>) : null}</div>
            </div>
        </div>
    );
};
function App() {
    return (
        <div
            className="content"
            style={{
                margin: "0 auto",
                maxWidth: "1520px",
                paddingBottom: "120px",
            }}
        >
            <div
                className="hero"
                style={{
                    position: "relative",
                    height: window.innerHeight,
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                }}
            >
                <div
                    className="row"
                    style={{
                        top: "24px",
                    }}
                >
                    <a href="mailto:schw.justin@gmail.com" target="_blank" rel="noopener noreferrer">
                        email
                    </a>

                    <a href="https://twitter.com/schw_justin" target="_blank" rel="noopener noreferrer">
                        twitter
                    </a>

                    <a href="https://linkedin.com/in/schwjustin" target="_blank" rel="noopener noreferrer">
                        linkedin
                    </a>
                </div>
                <h1 style={{ textAlign: "center", maxWidth: "800px", margin: "0 auto" }}>
                    Justin Schwartz — Focused on Software Engineering, Product Design, and Startups.
                    <br />
                    <br />
                    ⁂
                    <br />
                    <br />
                    Interested in Social Media, AI, and Genetics.
                </h1>

                <div
                    className="row projRow"
                    style={{
                        bottom: "24px",
                    }}
                >
                    <p
                        onClick={() => {
                            const element = document.getElementById("finance");
                            element.scrollIntoView();
                        }}
                    >
                        string finance
                    </p>
                    <p
                        onClick={() => {
                            const element = document.getElementById("bridge");
                            element.scrollIntoView();
                        }}
                    >
                        bridge
                    </p>
                    <p
                        onClick={() => {
                            const element = document.getElementById("news");
                            element.scrollIntoView();
                        }}
                    >
                        string news
                    </p>
                    <p
                        onClick={() => {
                            const element = document.getElementById("macro");
                            element.scrollIntoView();
                        }}
                    >
                        macro
                    </p>
                    <p
                        onClick={() => {
                            const element = document.getElementById("terrestrial");
                            element.scrollIntoView();
                        }}
                    >
                        terrestrial
                    </p>
                    <p
                        onClick={() => {
                            const element = document.getElementById("loop");
                            element.scrollIntoView();
                        }}
                    >
                        the loop
                    </p>
                </div>
            </div>
            <div className="projects">
                <Project
                    id="finance"
                    name="String Finance — Product ↗"
                    link="https://stringfinance.app"
                    skills={["figma", "react", "next.js", "ionic", "tailwind"]}
                    img="finance"
                />
                <Project
                    id="bridge"
                    name="Bridge — Product ↗"
                    link="https://www.instagram.com/reel/Cg0nliLp70n"
                    skills={["figma", "react", "tailwind", "webpack", "web3.js", "node.js", "express"]}
                    img="bridge"
                />
                <Project
                    id="news"
                    name="String News — Product ↗"
                    link="https://stringnews.xyz"
                    skills={["figma", "swift", "swiftui", "uikit"]}
                    img="news"
                />
                <Project id="macro" name="Macro — Product" skills={["figma", "swift", "swiftui"]} img="macro" />
                <Project
                    id="terrestrial"
                    name="Terrestrial — Hackathon Project"
                    skills={["figma", "react", "tailwind", "dall•e 2"]}
                    img="terrestrial"
                    className="tBorder"
                />
                <Project
                    id="loop"
                    name="The Loop — Product Experiment"
                    skills={["figma", "swift", "swiftui"]}
                    img="loop"
                    className="projBorder"
                />
            </div>
        </div>
    );
}

export default App;
